import React from 'react'
import { graphql } from 'gatsby'
import { ArticleLayout } from "../layouts/"

export const query = graphql`
    query PageTemplateQuery($id: String!) {
        model: sanityPage(id: { eq: $id }) {
            ...PageTemplate
        }
    }
`

const PageTemplate = (props) => {

    return (
        <ArticleLayout {...props} />
    )

}

export default PageTemplate
